<template>
  <div>

    <v-row dense>
      <v-col cols="4" md="2">
        <select-pos-model v-model="id_pos" :label="$t('labels.pos')" dense outlined clearable
          @change="onPosUpdate"></select-pos-model>
      </v-col>
      <template v-if="!isMobileAgent() && checkPermission(['pos_receipt_stamp_print_uid'])">
        <v-col cols="4" md="2">
          <v-autocomplete v-model="isPrintAgain" :items="printStampOptions" :label="$t('labels.print_stamp_again')"
            dense outlined class="c-input-small"></v-autocomplete>
        </v-col>
        <v-col cols="4" md="2">
          <v-autocomplete v-model="stampSize" :disabled="!isPrintAgain" :items="sizeStampOptions"
            :label="$t('labels.stamp_size')" dense outlined class="c-input-small"></v-autocomplete>
        </v-col>
      </template>
      <v-col cols="8" md="6">
        <input-qr-scan-model v-model="code" ref="codeRef" :label="placeholder"
          @keyupEnter="inputScan"></input-qr-scan-model>
      </v-col>
    </v-row>

    <div class="mb-5 mt-2">
      <div>
        <div class="d-flex align-center justify-space-between mb-3">
          <div :class="{ 'error--text text-decoration-underline': [1, 7].includes(step) }">
            <span class="font-weight-medium" v-if="basket_code">{{ $t('labels.basket_code') }}:</span>
            <span class="font-weight-medium" v-else-if="location_cell_id">{{ $t('labels.location') }}:</span>
            <span class="font-weight-medium" v-else>{{ $t('labels.basket_code_or_location') }}:</span>
            {{ basket_code || location_cell_id }}
          </div>
          <div>
            <span class="font-weight-medium mr-1">{{ $t('labels.scanned') }}:</span>
            <span class="info--text">{{ uids.length }}</span>
            <!-- / <span class="success--text">{{ receiptItem.request_quantity }} </span> -->
          </div>
        </div>

        <div class="mb-3" :class="{ 'error--text text-decoration-underline': [2, 7].includes(step) }">
          <span class="font-weight-medium">{{ $t('labels.uid') }}:</span>
          <v-chip small v-for="uid in uids" :key="uid" class="ml-1">{{ uid }}</v-chip>
        </div>

        <div class="d-flex align-center justify-space-between mb-3">
          <div :class="{ 'error--text text-decoration-underline': step === 3 }">
            <span class="font-weight-medium">{{ $t('labels.d_size') }}:</span>
            {{ d_size }}
          </div>
          <div :class="{ 'error--text text-decoration-underline': step === 4 }">
            <span class="font-weight-medium">{{ $t('labels.r_size') }}:</span>
            {{ r_size }}
          </div>
          <div :class="{ 'error--text text-decoration-underline': step === 5 }">
            <span class="font-weight-medium">{{ $t('labels.c_size') }}:</span>
            {{ c_size }}
          </div>
          <div :class="{ 'error--text text-decoration-underline': step === 6 }">
            <span class="font-weight-medium">{{ $t('labels.weight_1') }}:</span>
            {{ weight }}
          </div>
        </div>

        <v-row>
          <v-col cols="3" v-if="receiptItem.url_images">
            <ImageViewer :url="receiptItem.url_images" height="106px" />
          </v-col>
          <v-col :cols="receiptItem.url_images ? 9 : 12">
            <div class="mb-3">
              <span class="font-weight-medium">{{ $t('labels.name') }}:</span>
              {{ receiptItem.name || '' }}
            </div>
            <div class="mb-3">
              <span class="font-weight-medium">{{ $t('labels.goods_description') }}:</span>
              {{ receiptItem.description || '' }}
            </div>
            <div class="mb-3">
              <span class="font-weight-medium">{{ $t('labels.size') }}:</span>
              {{ receiptItem.goods_size || '' }}
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col cols="6">
            <div  v-if="!basket_code && !receiptItem.id">
              <v-btn small block color="warning" @click="showDialogNoGoods">
                {{ $t('labels.no_goods') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6">
            <div v-if="!basket_code && !receiptItem.id">
              <v-btn small block color="success" @click="showDialogFinishSession">
                Hoàn thành phiên
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- <div class="mt-5" v-if="checkPermission(['pos_goods_receipt_uid_multiple']) && !!basket_code">
          <v-btn small block color="purple" outlined @click="showDialogScanMultiple">
            {{ $t('labels.scan_multiple') }}
          </v-btn>
        </div> -->
      </div>
    </div>

    <v-dialog v-model="dialogNoGoods" persistent max-width="350px">
      <ConfirmNoGoods v-if="dialogNoGoods" @cancel="hideDialogNoGoods" />
    </v-dialog>

    <v-dialog v-model="finishSessionDialog" persistent max-width="350px">
      <ReceiptFinishSession v-if="finishSessionDialog" @cancel="hideDialogFinishSession" />
    </v-dialog>

    <v-dialog v-model="dialogScanMultiple" persistent max-width="400px">
      <ScanMultipleStamp v-if="dialogScanMultiple" @cancel="hideDialogScanMultiple" @onItemScanned="onItemScanned" />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "HasStamp",
  components: {
    ImageViewer: () => import('@/components/common/ImageViewer'),
    ConfirmNoGoods: () => import('@/components/pos_goods/ConfirmNoGoods'),
    ReceiptFinishSession: () => import('@/components/pos_goods/ReceiptFinishSession'),
    ScanMultipleStamp: () => import('@/components/pos_goods/ScanMultipleStamp'),
  },
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    d_size: null,
    r_size: null,
    c_size: null,
    weight: null,
    id_pos: null,
    basket_code: null,
    location_cell_id: null,
    receiptItem: {},
    receiptGroup: {},
    uids: [],
    dialogNoGoods: false,
    dialogScanMultiple: false,
    isPositionCheck: false,
    isPrintAgain: false,
    finishSessionDialog: false,
    stampSize: '50x20',
    printStampOptions: [
      {
        text: 'Có',
        value: true
      },
      {
        text: 'Không',
        value: false
      },
    ],
    sizeStampOptions: [
      {
        text: '50x20',
        value: '50x20'
      },
      {
        text: '50x40',
        value: '50x40'
      },
    ]
  }),
  computed: {
    step() {
      if (!this.basket_code && !this.location_cell_id) {
        return 1
      } else if (!this.receiptItem || !this.receiptItem.id) {
        return 2
      } else if (this.receiptItem.id && !this.d_size) {
        return 3
      } else if (this.receiptItem.id && !this.r_size) {
        return 4
      } else if (this.receiptItem.id && !this.c_size) {
        return 5
      } else if (this.receiptItem.id && !this.weight) {
        return 6
      } else {
        return 7
      }
    },
    placeholder() {
      switch (this.step) {
        case 1:
          return this.$t('labels.basket_code_or_location')
        case 2:
          return this.$t('labels.uid')
        case 3:
          return this.$t('labels.d_size')
        case 4:
          return this.$t('labels.r_size')
        case 5:
          return this.$t('labels.c_size')
        case 6:
          return this.$t('labels.weight_1')
        case 7:
          if (this.location_cell_id) {
            return this.$t('labels.uid_or_position')
          } else {
            return this.$t('labels.uid_or_basket')
          }
        default:
          return '--- ERROR ---'
      }
    },
  },
  mounted() {
    if (window.me && window.me.working && window.me.working.receipt && window.me.working.receipt_item) {
      const working = JSON.parse(window.me.working.receipt_item)
      this.d_size = working.d_size
      this.r_size = working.r_size
      this.c_size = working.c_size
      this.weight = working.weight
      this.id_pos = working.id_pos
      this.basket_code = working.basket_code
      this.location_cell_id = working.location_cell_id
      this.receiptItem = { ...working.receiptItem }
      this.receiptGroup = { ...working.receiptGroup }
      this.uids = [...working.uids]
    }
  },
  methods: {
    showDialogFinishSession() {
      this.finishSessionDialog = true
    },
    hideDialogFinishSession() {
      this.finishSessionDialog = false
    },
    cancel() {
      this.$emit('cancel')
      this.$emit('refreshData')
    },
    onPosUpdate() {
      if (!this.id_pos) {
        return false
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput()
      })
    },
    restartAll() {
      this.isLoading = false
      this.isPositionCheck = false
      this.code = null
      this.d_size = null
      this.r_size = null
      this.c_size = null
      this.weight = null
      this.basket_code = null
      this.location_cell_id = null
      this.receiptItem = {}
      this.receiptGroup = {}
      this.uids = []
    },
    showDialogNoGoods() {
      this.dialogNoGoods = true
    },
    hideDialogNoGoods() {
      this.dialogNoGoods = false
    },
    showDialogScanMultiple() {
      this.dialogScanMultiple = true
    },
    hideDialogScanMultiple() {
      this.dialogScanMultiple = false
    },
    onItemScanned(items) {
      const item = items[0]
      if (this.receiptItem && this.receiptItem.sku && this.receiptItem.sku != item.sku) {
        this.code = null
        this.$vToastify.warning(this.$t('messages.wrong_goods'));
        return false
      }

      items.forEach(otem => {
        if (!this.uids.includes(otem.sku_uid)) {
          this.uids.push(otem.sku_uid)
        }
      })

      this.receiptItem = {
        ...item,
        // receipted_quantity: item.receipted_quantity + (this.uids.length)
      }
      if (!this.weight || !this.d_size || !this.r_size || !this.c_size) {
        this.d_size = this.receiptItem.d_size
        this.r_size = this.receiptItem.r_size
        this.c_size = this.receiptItem.c_size
        this.weight = this.receiptItem.weight
      }

      this.$root.$emit('playSuccessAudio')
      this.code = null
      this.hideDialogScanMultiple()

      this.updateWorking({
        type: 'receipt', item: JSON.stringify({
          d_size: this.d_size,
          r_size: this.r_size,
          c_size: this.c_size,
          weight: this.weight,
          basket_code: this.basket_code,
          receiptItem: this.receiptItem,
          uids: this.uids,
        })
      })
    },
    async inputScan() {
      if (!this.code) {
        return false
      }
      //
      switch (this.step) {
        case 1:
          await this.scanBasket()
          break;
        case 2:
          await this.scanUid()
          break;
        case 3:
          await this.setDSize()
          break;
        case 4:
          await this.setRSize()
          break;
        case 5:
          await this.setCSize()
          break;
        case 6:
          await this.setWeight()
          break;
        case 7:
          if (this.code == this.basket_code || this.code == this.location_cell_id) {
            await this.confirmBasketUid()
          } else {
            await this.scanUid()
          }
          break;
        default:
          this.$vToastify.error(' --- ERROR --- ')
          this.$root.$emit('playErrorAudio')
          break;
      }
    },
    async scanUid() {
      if (this.uids.includes(this.code)) {
        this.code = null
        this.$root.$emit('playErrorAudio')
        this.$vToastify.error(this.$t('messages.uid_scanned'))
        return false
      }

      /* if (this.uids.length > 0) {
        const uid = this.uids[0]
        const [oSku] = uid.split('@')
        if (this.code.includes('@')) {
          const [nSku] = this.code.split('@')
          if (oSku !== nSku) {
            this.code = null
            this.$vToastify.warning(this.$t('messages.wrong_goods'));
            return false
          }
        }
      } */

      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/receipt-get-goods-has-stamp', {
          code: this.code,
          group_tracking: this.receiptItem && this.receiptItem.group_tracking || null
        })

        if (!this.isPositionCheck) {
          const isPositionCheck = await this.checkPosition()
          if (!isPositionCheck) {
            this.code = null
            this.isLoading = false
            throw new Error('')
          }
          this.isPositionCheck = isPositionCheck
        }

        if (this.receiptItem && this.receiptItem.group_tracking && this.receiptItem.group_tracking != data.group_tracking) {
          this.code = null
          this.isLoading = false
          this.$vToastify.warning('Khác phiên nhập kho');
          document.getElementById('LoiNhamSPNhapKhoSPDanUID_player').play()
          return false
        }
        this.uids.push(data.sku_uid)

        if (!this.receiptGroup[data.id_goods]) {
          this.receiptGroup[data.id_goods] = []
        }
        this.receiptGroup[data.id_goods].push(data.sku_uid)

        this.receiptItem = {
          ...data,
          // receipted_quantity: data.receipted_quantity + (this.uids.length)
        }

        if (this.isPrintAgain && this.checkPermission(['pos_receipt_stamp_print_uid'])) {
          const stampName = `${this.stampSize}_${data.sku_uid}.pdf`
          await this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/generate-stamp-again`, {
            code: data.sku_uid,
            id_pos: this.id_pos,
            stamp_size: this.stampSize
          }, stampName)
        }

        if (!this.weight || !this.d_size || !this.r_size || !this.c_size) {
          this.d_size = this.receiptItem.d_size
          this.r_size = this.receiptItem.r_size
          this.c_size = this.receiptItem.c_size
          this.weight = this.receiptItem.weight
        }

        this.$root.$emit('playSuccessAudio')
        this.code = null
        this.isLoading = false

        this.updateWorking({
          type: 'receipt', item: JSON.stringify({
            d_size: this.d_size,
            r_size: this.r_size,
            c_size: this.c_size,
            weight: this.weight,
            id_pos: this.id_pos,
            basket_code: this.basket_code,
            location_cell_id: this.location_cell_id,
            receiptItem: this.receiptItem,
            receiptGroup: this.receiptGroup,
            uids: this.uids,
          })
        })
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.$root.$emit('playErrorAudio')
        this.code = null
        this.isLoading = false
      }
    },
    async checkPosition() {
      try {
        await httpClient.post('/receipt-get-basket-or-location', {
          code: this.basket_code || this.location_cell_id,
          id_pos: this.id_pos,
        })
        return true
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        return false
      }
    },
    async scanBasket() {
      try {
        const { data } = await httpClient.post('/receipt-get-basket-or-location-no-check', {
          code: this.code,
          id_pos: this.id_pos,
          // id_goods: this.receiptItem.id_goods,
        })
        this.code = null
        /* if (!data.basket) {
          this.$vToastify.error(this.$t('messages.wrong_basket_code'));
          this.$root.$emit('playErrorAudio')
          return false
        }
        this.basket_code = data.basket.code */
        if (data.basket) {
          this.basket_code = data.basket.code
        } else if (data.location) {
          this.location_cell_id = data.location.cell_id
        } else {
          this.$vToastify.error(this.$t('messages.wrong_basket_code'));
          this.$root.$emit('playErrorAudio')
          return false
        }
        this.$root.$emit('playSuccessAudio')
      } catch (e) {
        console.error(e)
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.code = null
        this.$root.$emit('playErrorAudio')
      }
    },
    async confirmBasketUid() {
      try {
        await httpClient.post('/receipt-check-basket-and-create-history-has-stamp-multi-goods', {
          basket_code: this.basket_code,
          location_cell_id: this.location_cell_id,
          id_pos: this.id_pos,
          group_tracking: this.receiptItem.group_tracking,
          group_uids: this.receiptGroup,
          uids: this.uids,
          /* id_goods: this.receiptItem.id_goods,
          d_size: this.d_size,
          r_size: this.r_size,
          c_size: this.c_size,
          weight: this.weight, */
        })

        this.$root.$emit('playSuccessAudio')
        this.updateWorking({
          type: 'receipt', item: null
        })
        this.restartAll()
      } catch (e) {
        console.error(e)
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.code = null
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },
    async setDSize() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t('messages.not_positive_integer'));
        this.$root.$emit('playErrorAudio')
        this.code = null
        return false
      }
      this.d_size = +this.code
      this.code = null
      this.$root.$emit('playSuccessAudio')
    },
    async setRSize() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t('messages.not_positive_integer'));
        this.$root.$emit('playErrorAudio')
        this.code = null
        return false
      }
      this.r_size = +this.code
      this.code = null
      this.$root.$emit('playSuccessAudio')
    },
    async setCSize() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t('messages.not_positive_integer'));
        this.$root.$emit('playErrorAudio')
        this.code = null
        return false
      }
      this.c_size = +this.code
      this.code = null
      this.$root.$emit('playSuccessAudio')
    },
    async setWeight() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t('messages.not_positive_integer'));
        this.$root.$emit('playErrorAudio')
        this.code = null
        return false
      }
      this.weight = +this.code
      this.code = null
      this.$root.$emit('playSuccessAudio')
      // set weight here
      /* this.updateWorking({
        type: 'receipt', item: JSON.stringify({
          id_pos: this.id_pos,
          d_size: this.d_size,
          r_size: this.r_size,
          c_size: this.c_size,
          weight: this.weight,
          basket_code: this.basket_code,
          location_cell_id: this.location_cell_id,
          receiptItem: this.receiptItem,
          receiptGroup: this.receiptGroup,
          uids: this.uids,
        })
      }) */
    },
  }
}
</script>

<style scoped></style>
